import React, { useEffect, useState } from "react"

import { TemplateContextProvider } from 'components/post/TemplateProvider'
import { PlacesContextProvider } from 'components/post/PlacesProvider'
import { pageStore } from "state/store-zustand";
import usePageLoading from 'components/utils/usePageLoading'

import Header from "components/header"
import MapLayout from 'components/map/mapLayout'
import FetchEvents from 'components/utils/fetchEvents'
import FetchPlaces from 'components/utils/fetchPlaces'

import "styles/pages.scss"
import "styles/map_page.scss"

const MapPage = props => {
  let { slug } = props

  // TODO: OR like this?
  //const embedded = useEmbedded();
  const pageLoader = usePageLoading()

  const fetchEvents = true
  const fetchPlaces = true

  const embedded = pageStore((state) => state.embedded)
  const hasParams = pageStore((state) => state.hasParams)

  // TODO: use zustand and reduce 1 render
  const [isClient, setClient] = useState(false)
  useEffect(() => { setClient(true) }, [])

  const pageClass = `page map ${pageLoader} ${embedded ? 'embedded' : ''}`

  // Don't SSR if there are URL params
  // TODO: Handle this with Next initial router props
  if (isClient != true && hasParams) {
    return null
  }

  return (
    <div className={pageClass}>
      {/* Fetch data inside the place context; Instead of a hook, outside of context */}
      {fetchEvents
        ? <FetchEvents />
        : null
      }
      {fetchPlaces
        ? <FetchPlaces />
        : null
      }
      <main>
        {embedded != true
          ? <Header />
          : null
        }

        <MapLayout
          slug={slug}
          showLogo={embedded ? true : false}
          hasPlace
          title='Explore the Map' />
      </main>
    </div>
  )
}

export default MapPage